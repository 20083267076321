import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  Hero,
  BigBackgroundSectionTitle,
  SEO,
  Video,
  OtherCaseStudies,
  LikeWhatYouSee
} from 'components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import BlockContent from '@sanity/block-content-to-react';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import PrevArrow from 'images/shared/PrevArrow';
import { useLocation } from '@reach/router';
import { NetlifyIdentityContext } from 'context';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useScrollPercentage } from 'react-scroll-percentage';
import { useInView } from 'react-intersection-observer';

const CaseStudyTemplate = ({ data, pageIsPrivate }) => {
  const netlifyIdentityContext = pageIsPrivate && useContext(NetlifyIdentityContext);
  const user = netlifyIdentityContext?.user;
  const location = useLocation();
  const { caseStudyPost, otherCaseStudiesData } = data;
  const {
    company,
    whatWeDid,
    _rawDetails,
    _rawProjectVisuals,
    leadingParagraph,
    subHeader,
    industry,
    work,
    site,
    previewImage,
    awardBadge,
    slug
  } = caseStudyPost.edges[0].node;

  const { windowWidth } = useWindowSize();
  const otherCaseAmount = windowWidth >= 1100 ? 3 : 2;

  const sanityProjectDetails = { projectId: '4poiqg7l', dataset: 'production' };

  const serializer = {
    types: {
      caseStudyImage({ node }) {
        const imageData = getGatsbyImageData(
          node.asset._ref,
          { maxWidth: '100%' },
          sanityProjectDetails
        );
        return (
          <CaseStudy style={{ position: 'relative' }} $hasCaption={node.caption}>
            {node.caption ? <Caption>{node.caption}</Caption> : null}
            <CaseStudyImg image={imageData} alt={node.alt} />
          </CaseStudy>
        );
      },
      vimeoVideo: (props) => (
        <Video style={{ marginBottom: '1.25rem' }} title={props.node.text} url={props.node.url} />
      )
    },
    marks: {
      sup: (props) => <sup>{props.children}</sup>,
      sub: (props) => <sub>{props.children}</sub>
    }
  };

  return (
    <>
      <SEO
        pageSpecificTitle={`${whatWeDid} | ${company}`}
        pageSpecificDescription={leadingParagraph}
        pageSpecificThumbnail={getImage(previewImage)}
      />
      <Hero>
        <HeroContent company={company}>
          {!user ||
            (user?.email !== 'guest@agencyx.ie' && (
              <StyledLink
                to={
                  location.pathname.includes('/admin')
                    ? '/admin/non-public-case-studies/'
                    : '/our-work/'
                }>
                <BackButton>
                  <StyledPrevArrow />
                </BackButton>
              </StyledLink>
            ))}
          {awardBadge && (
            <AwardBadge image={awardBadge.asset.gatsbyImageData} alt={awardBadge.alt} />
          )}
          <WhatWeDid dangerouslySetInnerHTML={{ __html: whatWeDid }} />
          <h1 dangerouslySetInnerHTML={{ __html: company }} />
          {subHeader && <h2 dangerouslySetInnerHTML={{ __html: subHeader }} />}
          <LeadingParagraph dangerouslySetInnerHTML={{ __html: leadingParagraph }} />
          <DetailsAndIndustryAndWorkWrapper>
            <Details>
              <BlockContent blocks={_rawDetails} serializers={serializer} />
            </Details>
            <IndustryAndWorkWrapper>
              {industry && (
                <IndustryOrWork>
                  <h3>Industry</h3>
                  <p dangerouslySetInnerHTML={{ __html: industry }} />
                </IndustryOrWork>
              )}
              {work.length > 0 && (
                <IndustryOrWork>
                  <h3>Work</h3>
                  <p>{work.join(', ')}</p>
                </IndustryOrWork>
              )}
              {site && (
                <IndustryOrWork>
                  <h3>Website</h3>
                  <a
                    href={`https://${site}/`}
                    dangerouslySetInnerHTML={{
                      __html: site.includes('annual-conference-2024')
                        ? 'IPHA Conference 2024'
                        : site
                    }}
                    target="_blank"
                    rel="noreferrer"
                  />
                </IndustryOrWork>
              )}
            </IndustryAndWorkWrapper>
          </DetailsAndIndustryAndWorkWrapper>
        </HeroContent>
        <div style={{ position: 'relative', marginBottom: '8.5rem' }}>
          {windowWidth >= 1100 && (
            <BigBackgroundSectionTitle style={{ top: '-189px' }}>Project</BigBackgroundSectionTitle>
          )}
          <Block>
            <BlockContent blocks={_rawProjectVisuals} serializers={serializer} />
          </Block>
        </div>
      </Hero>
      <LikeWhatYouSee />
      <Hero>
        <OtherCaseStudies data={otherCaseStudiesData.edges} />
      </Hero>
    </>
  );
};

const HeroContent = styled.div`
  h1 {
    margin-top: 0.125rem;
  }
  h2 {
    font-size: 2.063rem;
    font-weight: 900;
  }
  a {
    color: #000;
  }
  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
    h1 {
      position: relative;
      left: -0.188rem;
      ${({ company }) =>
        (company === 'Pfizer & Bristol Myers Squibb' ||
          company === "Agri Aware,<br />Irish Farmers' Association") &&
        'font-size: 4.625rem'};
    }
    h2 {
      font-size: 3rem;
      margin-top: -0.3em;
      margin-bottom: 0.6em;
    }
  }
  @media screen and (min-width: 68.75rem) {
    max-width: 73.75rem;
    margin: -3.5rem 0 9.1rem;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: -0.75em;
`;

const AwardBadge = styled(GatsbyImage)`
  position: fixed;
  right: 1.25rem;
  top: 6.35rem;
  width: 7.8rem;
  z-index: 1;

  @media screen and (min-width: 48rem) {
    right: 6.25%;
    top: 4.45rem;
  }

  @media screen and (min-width: 68.75rem) {
    width: 12rem;
    top: 4.9rem;
  }

  @media screen and (min-width: 75rem) {
    top: 5.75rem;
  }

  @media screen and (min-width: 90rem) {
    right: 9%;
    width: 13.25rem;
  }
`;

const WhatWeDid = styled.p`
  font-weight: 300;
  margin: 1.05em 0 0.45em;
  @media screen and (min-width: 48rem) {
    margin: 1.05em 0 -0.325em;
  }
  @media screen and (min-width: 68.75rem) {
    margin: 1.05em 0 -0.25em;
    font-size: 2rem !important;
  }
`;

const LeadingParagraph = styled.p`
  margin: 1.05em 0 1.05em;
  max-width: 32.5em;
  @media screen and (min-width: 48rem) {
    margin: 1.55em 0 3.35em;
  }
  @media screen and (min-width: 68.75rem) {
    font-size: 2rem !important;
    line-height: 1.25em !important;
    margin: 0.725em 0 2.125em;
  }
`;

const DetailsAndIndustryAndWorkWrapper = styled.div`
  @media screen and (min-width: 68.75rem) {
    display: flex;
    justify-content: space-between;
    max-width: 65.75rem;
  }
`;

const Details = styled.div`
  margin-bottom: 1.15em;
  p {
    font-size: 1rem !important;
    max-width: 52.5em;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  @media screen and (min-width: 48rem) {
    margin-bottom: 2.3em;
  }
`;

const IndustryAndWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 48rem) {
    flex-direction: row;
    margin-bottom: 3.875rem;
  }
  @media screen and (min-width: 68.75rem) {
    flex-direction: column;
    margin-left: 2.625rem;
    max-width: 236px;
  }
`;

const IndustryOrWork = styled.div`
  margin-bottom: 1.12em;
  h3 {
    font-size: 1rem;
    font-weight: 900;
    text-transform: lowercase;
  }
  p,
  a {
    font-size: 0.875rem;
    margin-top: 0.5em;
    white-space: pre-line;
  }
  a {
    color: #000;
  }
  @media screen and (min-width: 48rem) {
    &:last-of-type {
      margin-left: 8rem;
    }
  }
  @media screen and (min-width: 68.75rem) {
    &:last-of-type {
      margin-left: 0;
    }
  }
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const CaseStudyImg = styled(GatsbyImage)`
  margin-bottom: 1.25rem;
  height: 100% !important;
  width: 100% !important;
  opacity: 1;

  div {
    max-width: 100% !important;
  }
`;

const CaseStudy = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const Caption = styled.h4`
  color: #000;
  font-family: 'proxima-nova';
  font-size: 1.5rem;
  font-weight: 800;
  margin: 0.2em 0 0.25em 0.5em;
  text-transform: capitalize;

  @media screen and (min-width: 34.375em) {
    margin: 0.2em 0 0.25em 1em;
  }

  @media screen and (min-width: 48rem) {
    font-size: 2rem;
    margin-top: 0.325em;
    margin-bottom: 0.25em;
    margin-left: 2.55em;
    margin: 0.325em 0 0.25em 2.55em;
  }
`;

const Block = styled.div`
  p {
    font-size: 1rem;
    margin-bottom: 2rem;
    max-width: 700px;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0 1rem 0;
    @media screen and (min-width: 48rem) {
      margin-top: 3rem;
    }
  }
`;

export const query = graphql`
  query ($slug: String!) {
    caseStudyPost: allSanityCaseStudy(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          title
          company
          subHeader
          whatWeDid
          slug {
            current
          }
          publicFacingCaseStudy
          leadingParagraph
          _rawDetails
          details {
            _type
            _key
            children {
              _key
              _type
              text
              marks
            }
            _rawChildren
            list
            style
          }
          industry
          work
          site
          previewImage {
            alt
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 671, placeholder: BLURRED, formats: AUTO)
            }
          }
          awardBadge {
            alt
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 230, placeholder: BLURRED, formats: AUTO)
            }
          }
          projectVisuals {
            _rawChildren
            _key
            _type
            children {
              _key
              _type
              marks
              text
            }
            list
            style
          }
          _rawProjectVisuals
        }
      }
    }
    otherCaseStudiesData: allSanityCaseStudy(
      limit: 3
      sort: { fields: _createdAt }
      filter: { publicFacingCaseStudy: { eq: true }, slug: { current: { ne: $slug } } }
    ) {
      edges {
        node {
          title
          company
          whatWeDid
          slug {
            current
          }
          previewImage {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 340, formats: AUTO)
              altText
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyTemplate;
